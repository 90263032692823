<template>
  <BaseLayout v-slot>
    <section v-if="currentstore != null" class="breadcrumb py-0 mb-0">
      <Breadcrumbs />
    </section>
    <section
      v-if="currentstore != null"
      class="our-shop--banner pb-25 pb-lg-35"
    >
      <b-container>
        <h1 class="font-weight-normal mb-30 mb-lg-35">
          {{ currentstore.name }}
        </h1>
        <div class="banner-img" v-if="currentstore.banner_image != null">
          <img :src="currentstore.banner_image.url" alt="Our Shop" />
        </div>
      </b-container>
    </section>
    <section v-if="currentstore != null" class="content pb-7">
      <b-container>
        <div v-html="currentstore.description"></div>
      </b-container>
    </section>
    <SubscribeSeparator />
    <section
      v-if="currentstore != null"
      class="address-and-opening-time pb-35 py-lg-10"
    >
      <b-container>
        <b-row>
          <b-col lg="3">
            <span class="h4 d-block">Openingstijden winkel</span>
            <ul class="opening-time list-unstyled pl-0 m-0 pb-30">
              <li
                class="d-flex"
                v-for="openingTimeDatum of openingTimes"
                :key="openingTimeDatum.label"
              >
                <span class="day d-block mr-25">{{
                  $t(openingTimeDatum.label)
                }}</span>
                <span
                  v-for="(item, index) in openingTimeDatum.hours"
                  :key="`${openingTimeDatum.label}-${index}`"
                  class="time d-block"
                >
                  <span v-if="item.from != null" class="time"
                    >{{ item.from }} - {{ item.to }}</span
                  >
                  <span v-if="item.from == null" class="time d-block">{{
                    $t("closed")
                  }}</span>
                </span>
              </li>
            </ul>
            <span class="h4 d-block">Adres:</span>
            <address v-if="currentstore != null">
              {{ currentstore.name }} <br />
              {{ currentstore.street }}, <br />
              {{ currentstore.postcode }} {{ currentstore.city }} <br />
              Telefoon {{ currentstore.phone }}
            </address>
          </b-col>
          <b-col lg="9" class="pt-5">
            <div
              class="map-holder"
              :style="{ backgroundImage: `url(${map})` }"
            ></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import map from "@/esf_lommel_dt1_europe/assets/map.jpg";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import SubscribeSeparator from "@/esf_lommel_dt1_europe/core/components//SubscribeSeparator";
//import { Logger } from "@storefront/core/lib/logger";
//import { isServer } from "@storefront/core/helpers";

export default {
  name: "OurShop",
  components: {
    BaseLayout,
    Breadcrumbs,
    SubscribeSeparator,
    //VueGoogleMaps: () => (!isServer ? import("vue2-google-maps") : null),
  },
  data() {
    return {
      openingTimeData: [
        { day: "Maandag", openingHours: "12.00 - 17.30" },
        { day: "Dinsdag", openingHours: "10.00 - 17.30" },
        { day: "Woensdag", openingHours: "10.00 - 17.30" },
        { day: "Donderdag", openingHours: "10.00 - 17.30" },
        { day: "Vrijdag", openingHours: "10.00 - 17.30" },
        { day: "Zaterdag", openingHours: "10.00 - 17.00" },
        { day: "Zondag", openingHours: "10.00 - 17.00" },
      ],
      map: map,
      largeScreen: false,
    };
  },
  mounted() {
    const bcrumb = {
      current: this.currentstore.name,
      routes: [{ name: "Winkels", route_link: "/winkels" }],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);

    window.addEventListener("resize", this.setLargeScreen);
    window.addEventListener("load", this.setLargeScreen);
    this.setLargeScreen();
  },
  destroyed() {
    window.removeEventListener("resize", this.setLargeScreen);
    window.removeEventListener("load", this.setLargeScreen);
  },
  computed: {
    currentstore() {
      return this.$store.getters["stores/getStoreByCode"](
        this.$route.params.code
      );
    },
    openingTimes() {
      if (this.currentstore != null) {
        return this.currentstore.openingHours;
      }
      return null;
    },
  },
  watch: {
    currentstore() {
      if (this.currentstore != null) {
        const bcrumb = {
          current: this.currentstore.name,
          routes: [{ name: "Winkels", route_link: "/winkel/dt1" }],
        };
        this.$store.commit("breadcrumbs/set", bcrumb);
      }
    },
  },
  methods: {
    setLargeScreen() {
      this.largeScreen = window.innerWidth > 991;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.breadcrumb {
  background-color: transparent;
  li {
    + li {
      position: relative;

      &:before {
        content: "\e876";
        font-family: "Linearicons-Free";
        font-size: 12px;
        position: absolute;
        top: 3px;
        left: 7px;
        padding: 0;
      }
    }

    &.active {
      a {
        pointer-events: none;
        font-family: "Work Sans", sans-serif;
        color: #0d1f47;
        letter-spacing: -0.07em;
      }
    }
  }
}

h1 {
  line-height: 1;
}

.banner-img {
  width: 100%;

  img {
    width: 100%;
  }
}

p {
  margin-bottom: 24px;
}

.opening-time {
  span {
    width: calc(50% - 20px);
    flex-shrink: 0;
    max-width: 113px;

    @include media("screen", ">=desktop") {
      max-width: none;
    }
  }
}

.map-holder {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: 65% 50%;

  @include media("screen", ">=desktop") {
    height: 417px;
  }
}
</style>
