<template>
  <aside class="filter">
    <b-link class="filter-opener d-lg-none" @click.prevent="toggleFilter"
      >Filters<span class="plus">+</span>
    </b-link>
    <div
      class="filter-overlay"
      @click.prevent="toggleFilter"
      v-bind:class="{ show: filterExpanded }"
    ></div>
    <div
      class="fake-overlay"
      v-if="overlayIsReady"
      :class="{ show: filterExpanded }"
    ></div>
    <transition name="slide-fade">
      <div class="filter-collapse" :class="{ show: filterExpanded }">
        <SearchProductFilterContent @clicked="toggleFilter" />
      </div>
    </transition>
  </aside>
</template>

<script>
import SearchProductFilterContent from "./SearchProductFilterContent.vue";
import { isServer } from "@storefront/core/helpers";
export default {
  name: "SearchProductFilter",
  components: {
    SearchProductFilterContent,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },

  created() {
    if (!isServer) {
      window.addEventListener("resize", this.resize);
    }
  },
  mounted() {
    this.overlayIsReady = true;
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    resize() {
      if (window.innerWidth > 991) this.filterExpanded = false;
    },
    toggleFilter() {
      this.filterExpanded = !this.filterExpanded;
    },
    removeSelectedItem(id) {
      const index = this.filterItemsSelected.findIndex(
        (item) => item.id === id
      );
      if (index > -1) {
        this.filterItemsSelected[index].isChecked = false;
      }
    },
  },
  data() {
    return {
      filterExpanded: false,
      overlayIsReady: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/main.scss";
.slide-fade-enter-active {
  transition: all 0.3s ease 0.4s;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  @include media("screen", "<desktop") {
    opacity: 0;
  }
}

.fake-overlay {
  @include media("screen", "<desktop") {
    transition: all 0.3s ease 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 99999;
    width: 90%;
    max-width: 370px;
    transform: translateX(-100%);
  }

  &.show {
    @include media("screen", "<desktop") {
      transition: all 0.5s ease;
      transform: translateX(0);
    }
  }
}

.filter-opener {
  display: block;
  border: 1px solid #000;
  padding: 8px 15px;
  position: relative;
  color: #000 !important;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;

  .plus {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    font-family: "Work Sans";
    font-size: 23px;
  }
}

.filter-collapse {
  @include animate(opacity visibility);

  transition-delay: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 90%;
  max-width: 370px;
  overflow-y: auto;
  padding: 0 15px;
  opacity: 0;
  visibility: hidden;

  @include media("screen", ">=desktop") {
    position: static;
    transform: none;
    padding: 0;
    width: auto;
    max-width: none;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    transition: none;
  }

  &.show {
    @include media("screen", "<desktop") {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.4s;
    }
  }
}

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  @include animate(opacity visibility);
  transition-delay: 0.3s;
  z-index: 9999;

  &.show {
    transition-delay: 0;
    visibility: visible;
    opacity: 0.9;
  }
}
</style>
