<template>
  <BaseLayout>
    <Breadcrumbs />
    <div class="container">
      <div class="row">
        <SearchProductFilter class="col-lg-3" />
        <ProductsListingSearch class="col-lg-9" />
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import SearchProductFilter from "@/esf_lommel_dt1_europe/core/components//SearchProductFilter";
import ProductsListingSearch from "@/esf_lommel_dt1_europe/core/components//ProductsListingSearch";

export default {
  name: "CategoryOverviews",
  components: {
    BaseLayout,
    Breadcrumbs,
    SearchProductFilter,
    ProductsListingSearch,
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchWord() {
      if (this.searchWord != "") {
        this.$store.dispatch("search/load", { search: this.searchWord });
        const bcrumb = { current: this.$route.query.q, routes: [] };
        this.$store.commit("breadcrumbs/set", bcrumb);
      }
    },
  },
  mounted() {
    this.$store.dispatch("search/load", { search: this.searchWord });
    const bcrumb = { current: this.searchWord, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  data() {
    return {
      //    bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
};
</script>

<style lang="scss" scoped></style>
