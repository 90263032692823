<template>
  <BaseLayout v-slot>
    <Breadcrumbs />
    <div class="container">
      <div class="row">
        <SearchProductFilter class="col-lg-3" />
        <div class="col-lg-9">
          <FilterSystem :samePage="true" style="padding-bottom: 10px" />
          <ProductsListingSearch />
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import SearchProductFilter from "@/esf_lommel_dt1_europe/core/components//SearchProductFilter";
import ProductsListingSearch from "@/esf_lommel_dt1_europe/core/components//ProductsListingSearch";
import FilterSystem from "@/esf_lommel_dt1_europe/core/components//FilterSystem";

export default {
  name: "PartFinder",
  components: {
    BaseLayout,
    Breadcrumbs,
    SearchProductFilter,
    ProductsListingSearch,
    FilterSystem,
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("search/loadPartfinder");
  },
  data() {
    return {
      //    bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.query).length > 0) {
      next({ path: to.path, query: {} });
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (Object.keys(to.query).length > 0) {
      next({ path: to.path, query: {} });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
