<template>
  <div>
    <AdvertisingBanner class="col-12 my-30 mt-lg-0" />
    <!-- <BestSellers /> -->
    <SearchPageContent />
  </div>
</template>

<script>
import SearchPageContent from "@/esf_lommel_dt1_europe/core/components//SearchPageContent";
import AdvertisingBanner from "@/esf_lommel_dt1_europe/core/components//AdvertisingBanner";
export default {
  name: "ProductsListingSearch",
  components: {
    //BestSellers,
    SearchPageContent,
    AdvertisingBanner,
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
h3,
.h3 {
  font-weight: 400;
  margin-bottom: 10px;
}

.bottom-txt {
  font-size: 14px;
  line-height: 1.72;
  padding-bottom: 14px;
  padding-top: 25px;

  @include media("screen", ">=tablet") {
    padding-top: 0;
    padding-bottom: 73px;
  }
}
</style>
